<section class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="coming-soon-content">
                    <h1>Grimerian, en construcción!</h1>
                    <p>Nuestro sitio web se encuentra en desarrollo, Gracias por tu paciencia.</p>
                    <form>
                        <input type="email" name="email" id="email" class="email-input" placeholder="Ingresa tu email">
                        <button type="submit" class="submit-btn">Suscribirse</button>
                    </form>
                    <div id="timer">
                        <div id="days"></div>
                        <div id="hours"></div>
                        <div id="minutes"></div>
                        <div id="seconds"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ul class="social-list">
        <li class="list-heading">Síguenos para recibir actualizaciones:</li>
        <li><a href="https://www.instagram.com/grimerian/" class="instagram" target="_blank"><i class="flaticon-instagram"></i></a></li>
        <li><a href="#" class="twitter" target="_blank"><i class="flaticon-twitter"></i></a></li>
    </ul>
</section>