<section class="footer-section pt-100 pb-70">
    <div class="container">
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="subscribe-content">
                        <h2>Boletín informativo</h2>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Ingresa tu email" name="EMAIL">
                        <button type="submit">Suscribirme ahora</button>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Sobre Nosotros</h3>
                    </div>
                    <p>Grimerian, innovación en Tecnologías Emergentes con enfoque en la calidad del servicio.</p>
                    <ul class="footer-social">
                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Mapa del sitio</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Nosotros</a></li>
                        <li><a routerLink="/">Proyectos</a></li>
                        <li><a routerLink="/">Servicios</a></li>
                        <li><a routerLink="/">Blog</a></li>
                        <li><a routerLink="/">Contacto</a></li>
                    </ul>
                </div>    
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Servicios Destacados</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Desarrollo Web/Mobile/Desktop</a></li>
                        <li><a routerLink="/">Automatizacion y RPA</a></li>
                        <li><a routerLink="/">Análisis de Datos</a></li>
                        <li><a routerLink="/">Inteligencia Artificial (AI)</a></li>
                        <li><a routerLink="/">Control/Gestios de Calidad QA/QC</a></li>
                    </ul>
                </div>    
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Contacto</h3>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-phone-call"></i>
                        <h3>Telefono</h3>
                        <span><a href="tel:+51956142826">+51 956 142 826</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-envelope"></i>
                        <h3>Email</h3>
                        <span><a href="mailto:alex.yabar@grimerian.com">alex.yabar@grimerian.com</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-pin"></i>
                        <h3>Dirección</h3>
                        <span><a href="#" target="_blank">Av. San Luis 2679, San Borja - Perú</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>©Grimerian <a href="/" target="_blank">todos los derechos reservados</a></p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/terms-conditions">Términos y Condiciones</a></li>
                        <li><a routerLink="/privacy-policy">Políticas de Privacidad</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="go-top"><i class="bx bx-chevron-up"></i><i class="bx bx-chevron-up"></i></div>

<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>Sobre Nosotros</h2>
                <p>Grimerian, innovación en Tecnologías Emergentes con enfoques en la calidad del servicio.</p>
            </div>
        </div>
        <div class="sidebar-contact-feed">
            <h2>Contacto</h2>
            <div class="contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Tu Nombre">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" placeholder="Tu Email">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Tu Telefono">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Asunto">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Tu Mensaje"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="send-btn-one">Enviar Mensaje</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="sidebar-contact-area">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:+51956142826">+51 956 142 826</a>
                        <span>ó</span>
                        <a href="mailto:alex.yabar@grimerian.com">alex.yabar@grimerian.com</a>
                    </h2>
                    <ul class="social">
                        <li><a href="https://www.instagram.com/grimerian/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn">
            <i class="flaticon-cancel"></i>
        </span>
    </div>
</div>