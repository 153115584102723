<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-box pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="flaticon-pin"></i>
                    <div class="content-title">
                        <h3>Address</h3>
                        <p>32 st Kilda Road, Melbourne VIC, 3004 Australia</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="flaticon-envelope"></i>
                    <div class="content-title">
                        <h3>Email</h3>
                        <a href="mailto:hello@avrax.com">hello@avrax.com</a>
                        <a href="mailto:fax@avrax.com">fax@avrax.com</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-contact-box">
                    <i class="flaticon-phone-call"></i>
                    <div class="content-title">
                        <h3>Phone</h3>
                        <a href="tel:123456123">+123(456)123</a>
                        <a href="tel:126446129">+126(446)129</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-text">
                    <h3>Have Any Questions About Us?</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed eiusmod tempor incididunt ut labore </p>
                </div>
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                        </div>
                        <div class="form-group">
                            <label>Subject</label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                        </div>
                        <div class="form-group">
                            <label>Message</label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                        </div>
                        <div class="send-btn">
                            <button type="submit" class="default-btn">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-image">
                    <img src="assets/img/contact.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>